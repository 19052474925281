import React, { useState } from "react";
import moment from "moment";
import MilestoneDetail from "./MilestoneDetail";

// import ReactHtmlParser, {
//   processNodes,
//   convertNodeToElement,
//   htmlparser2,
// } from "react-html-parser";

const Milestone = ({ milestone, index }) => {
  const [open, setOpen] = useState(false);
  // console.log("data", milestone.eventDate);
  return (
    <>
      <MilestoneDetail milestone={milestone} open={open} setOpen={setOpen} />
      {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
      <div className="mt-6 mb-10 sm:mt-0 sm:mb-1">
        <div className="flex flex-col items-center sm:flex-row">
          <div
            className={`${
              index % 2 === 0 ? "justify-start" : "justify-end"
            }  flex  w-full mx-auto items-center`}
          >
            <div
              className={`${
                index % 2 === 0 ? "sm:pr-14" : "sm:pl-14"
              } w-full sm:w-1/2 sm:pr-8`}
            >
              <div
                className="p-4 transition duration-500 ease-in rounded shadow cursor-pointer bg-gray-50 hover:bg-swa-4 hover:bg-opacity-25"
                onClick={() => setOpen(true)}
                onKeyDown={e => {
                  if (e.keyCode === 32) {
                    setOpen(true);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <div className="mb-1 text-sm antialiased font-semibold text-gray-700">
                  {milestone.title}
                </div>

                {/* {milestone.description && (
                  <div className="text-sm text-gray-600 line-clamp-1 sm:line-clamp-3 ">
                    {ReactHtmlParser(milestone.description)}
                  </div>
                )} */}
                {milestone.imgURL && (
                  <div className=" aspect-w-16 aspect-h-16">
                    <img
                      src={milestone.imgURL}
                      alt={milestone.title}
                      className="object-cover w-full py-2"
                    />
                  </div>
                )}
                {milestone.videoLink && (
                  <div className=" aspect-w-16 aspect-h-9">
                    <iframe
                      src={`https://www.youtube.com/embed/${milestone.videoLink}`}
                      frameBorder="0"
                      //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      className="w-full py-2"
                    />
                  </div>
                )}
                {milestone.pageLink && <></>}
                {milestone.description !== "" && (
                  <button
                    type="button"
                    className="inline-flex items-center px-1.5 py-0.5 border border-transparent text-xs font-medium rounded text-swa-1 bg-swa-4 bg-opacity-50 hover:bg-swa-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 mt-2"
                  >
                    read more
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* --- icon --- */}
          <div className="absolute flex items-center justify-center w-24 transform -translate-x-1/2 -translate-y-4 border-4 border-white left-1/2 sm:translate-y-0">
            <p className="text-xl font-bold bg-white md:font-normal md:text-sm text-swa-3">
              {moment(milestone.eventDate).format("YYYY")}
            </p>
            {/* <svg
             xmlns="http://www.w3.org/2000/svg"
             class="h-5 w-5 text-white"
             fill="none"
             viewBox="0 0 24 24"
             stroke="currentColor"
           >
             <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
             />
           </svg> */}
          </div>

          {/* ----- */}
        </div>
      </div>
    </>
  );
};

export default Milestone;
