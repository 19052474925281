import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout/layout";
import Milestone from "../../components/sections/about/Milestone";

import SEO from "../../components/seo";

const History = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        milestones {
          eventDate
          imgURL
          pageLink
          title
          videoLink
          description
        }
      }
    }
  `);
  const [milestones, setMilestones] = useState(data.SWAContent.milestones);

  const sortedMilestones = milestones.sort(
    (a, b) => new Date(b.eventDate) - new Date(a.eventDate)
  );

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Singapore Women's Association History and Milestones"
          description="Singapore Women's Association (SWA) started as Siglap Girls'
                Club in 1954 to serve the needs of underprivileged girls in
                Singapore."
        />
        <div className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <h1 className="text-swa-3">History and Milestones</h1>

          <section className="my-8">
            <h2 className="text-swa-3">Our Heritage</h2>
            {/* <h4>
              Internal Note (to be deleted): This section must containt at least
              one milestone
            </h4> */}
            <div className="text-lg text-gray-500">
              <p>
                Singapore Women's Association (SWA) started as Siglap Girls'
                Club in 1954 to serve the needs of underprivileged girls in
                Singapore. With the establishment of the People's Association in
                1960 to oversee activities for the Youth Movement, we decided to
                change our association name officially to SWA and shift our
                focus on empowering and helping women and the elderly. SWA was
                also known as 'Persatuan Wanita Singapura' during the days of
                Singapore’s merger with Malaysia. Since its inception, SWA had
                been rendering voluntary services to help to make Singapore a
                peaceful, happy and prosperous homeland for Singaporeans.
              </p>
              <br />
              <p>
                Throughout the years, we have started many initiatives and
                projects to create a better quality of life for everyone. Key
                on-going charity work includes the Singapore Vision Care
                Programme (free eye care/ eye screening for elderly), Annual
                Charity Dinner (anchor fundraising campaign for beneficiaries),
                Athena Academy (social enterprise to empower women in
                professional/ personal growth training) and many others.
              </p>
              <br />
              <p>
                SWA is also affiliated with the Singapore Council of Women’s
                Organisation (SCWO) and is a member of the National Council of
                Social Services (NCSS).
              </p>
            </div>
          </section>
          <section>
            <h2 className="text-swa-3">Milestones</h2>

            <div className="w-full px-2 py-3 sm:max-w-xl sm:mx-auto sm:px-0">
              <div className="relative ">
                {/* <!-- Vertical bar running through middle --> */}
                <div className="absolute hidden w-1 h-full transform -translate-x-1/2 sm:block bg-swa-4 left-1/2"></div>

                {sortedMilestones &&
                  sortedMilestones.map((milestone, index) => {
                    return (
                      <div key={index}>
                        <Milestone milestone={milestone} index={index} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default History;
