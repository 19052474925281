import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import ReactHtmlParser from "react-html-parser";

const MilestoneDetail = ({ milestone, open, setOpen }) => {
  const cancelButtonRef = useRef(null);
  // console.log("page link", milestone.pageLink);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="flex items-center justify-center p-2 mx-auto text-xl font-bold text-swa-2">
                  {moment(milestone.eventDate).format("MMM YYYY")}
                </div>
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-swa-2"
                  >
                    {milestone.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">
                      {ReactHtmlParser(milestone.description)}
                    </div>
                  </div>
                  {milestone.imgURL && (
                    <div className="p-3 my-2 border border-swa-4">
                      <img
                        src={milestone.imgURL}
                        alt={milestone.title}
                        className="object-cover w-full "
                      />
                    </div>
                  )}
                  {milestone.videoLink && (
                    <div className="my-2 border border-swa-4 aspect-w-16 aspect-h-9">
                      <iframe
                        src={`https://www.youtube.com/embed/${milestone.videoLink}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        className="w-full p-2"
                      />
                    </div>
                  )}
                  {milestone.pageLink && (
                    <div className="inline-block px-2 py-1 my-2 rounded bg-swa-4 text-swa-1">
                      <Link to={`/${milestone.pageLink}`}>view page</Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-swa-1 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MilestoneDetail;
